.thankyou_container {
    width: 100%;
    height: 95vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 2% 7%;
    text-align: center;
}

.check-icon {
    font-size: 70px;
    margin-bottom: 3rem;
}

.thankyou_subtitle {
    text-transform: uppercase;
    font-weight: 800;
    color: dodgerblue;
    margin-bottom: 1rem;
    font-size: initial;
}

.thankyou_title {
    font-size: 35px;
    margin-bottom: 1rem;
}

.thankyou_description {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 2rem;
    max-width: 1000px;
}

.thankyoubtn {
    width: 200px;
    border-radius: 0px;
}

@media screen and (max-width: 768px) {
    .check-icon {
        font-size: 40px;
        margin-bottom: 2rem;
    }
    .thankyou_title {
        font-size: 30px;
    }
    .thankyou_description {
        font-size: 12px;
    }
    .thankyoubtn {
        width: 150px;
    }
}