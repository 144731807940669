@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.btn{
  border: none;
  outline: none;
  background-color: transparent;
  padding: 10px;
  border-radius: 5px;
  font-size: 15px;
  color: #fff;
  width: 120px;
}

.btn_secondary {
  background-color: #888;
}

.btn_primary {
  cursor: pointer;
  background-color: #782bbb;
  cursor: pointer;
}