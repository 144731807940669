.contact_banner {
    background-image: url("../../assets/banner.png");
    width: 100%;
    background-position: center;
    background-size: cover;
    padding: 2% 7%;
    /* height: 350px; */
    color: #ffffff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
}

.contact_banner .contactheading {
    font-size: 30px;
    line-height: 35px;
    font-style: normal;
    font-weight: 600;
    max-width: 1100px;
}

.contact_banner .contact_detail {
    max-width: 1000px;
}


/* ----------------- contact form  */

.contact_container {
    display: grid;
    grid-template-columns: 0.54fr 1fr;
    gap: 3rem;
    padding: 2% 7%;
    margin-top: 3rem;
}

.contact_container .image {
    width: 550px;
}

.contact_container .image img {
    width: 100%;
}

.contact_form {
    width: 70%;
}

.contact_form .inputfeilds {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.2rem;
}

.contact_form .inputfeilds label {
    color: #782bbb;
    font-weight: 700;
    font-size: 17px;
}

.contact_form .inputfeilds input,
textarea {
    border: 1px solid #dadada;
    border-radius: 5px;
    font-size: 17px;
    margin-bottom: 1rem;
    outline: none;
    padding: 0.5rem;
    resize: none;
    width: 100%;
}

.radiofeilds {
    display: flex;
    gap: 2rem;
    margin-bottom: 1rem;
}

.inputradiofeilds {
    display: flex;
    justify-content: center;
    align-items: center;
}

.error_message {
    padding: 10px 0;
    color: red;
}

@media screen and (max-width: 1150px) {
    .contact_container .image {
        width: 400px;
    }
    .contact_form {
        width: 100%;
    }
}

@media screen and (max-width: 968px) {
    .contact_banner {
        height: auto;
    }
    .contact_banner .contactheading {
        font-size: 20px;
        line-height: 24px;
    }
    .contact_banner .contact_detail {
        max-width: 100%;
        font-size: 15px;
        margin-bottom: 1rem;
    }
    .contact_container {
        grid-template-columns: 1fr;
        gap: 3rem;
        padding: 2% 5%;
    }
    .contact_container .image {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 2rem;
    }
    .contact_container .image img {
        width: 80%;
    }
}